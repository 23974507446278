import { css } from '@emotion/react';
import { memo, useMemo } from 'react';
import { useSnapshot } from 'valtio';
import { fill_horizontal_all_center, } from '~/modules/AppLayout/FlexGridCss';
import Col_FaviconTitle from '~/pages/heineken_template/_col/col_FaviconTitle';
import styleds from '~/pages/sungop/_private/styleds';
import { sungop_store } from './sungop_store';
import { SymbolBidAsk } from './component/SymbolBidAsk';
import { AppLink2 } from '~/components/AppLink2';
import { useTw50StockList } from '~/modules/SDK/Symbol/useCommonSymbolList';
import { useStockWeightResource } from '~/modules/chips/useChipResource';
import { scrollbar2Css } from '~/css/scrollbarCss';
const indexs = ['TX-1', 'MTX-1', 'TSEA', 'TSE13', 'TSE17'];
//const stocks = ['2330', '2317', '2454', '2412', '6505']
export const Sungop_PageMenu = memo(function PageMenu(props) {
    const state = useSnapshot(sungop_store);
    const twse = useStockWeightResource('TWSE');
    const twse50 = useTw50StockList();
    const orderedTWSEStocks = useMemo(() => {
        return twse
            .filter(([k]) => twse50?.indexOf(k) !== -1)
            .map(([k, v]) => ({ symbol: k, percentage: v.percentage }))
            .sort((a, b) => b.percentage - a.percentage);
    }, [twse, twse50]).slice(0, 30);
    const stocks = orderedTWSEStocks.map(s => s.symbol);
    const symbolGroup = () => {
        if (state.menuSymbolMode === 'index') {
            return indexs;
        }
        if (state.menuSymbolMode === 'stock') {
            return stocks;
        }
    };
    return (<styleds.Sidebar>
      <Col_FaviconTitle.Display />

      <styleds.PageMenuBox>
        <AppLink2 href='/heineken_template'>吳極指標</AppLink2>
        <AppLink2 href='/heineken_template/stock-monitor-table'>龍頭監控分數</AppLink2>
        <AppLink2 href='/heineken_template/daily-chips'>每日籌碼</AppLink2>
        <AppLink2 href='/heineken_template/option-chips'>選擇權籌碼</AppLink2>
        <AppLink2 href='/heineken_template/option-state'>選擇權未平倉</AppLink2>
      </styleds.PageMenuBox>

      <styleds.PageMenuSymbolList>
        <div css={css `
            ${fill_horizontal_all_center};
            height: 30px;
            gap: 4px;
          `}>
          <styleds.PageMenuButton active={state.menuSymbolMode === 'index'} onClick={() => (sungop_store.menuSymbolMode = 'index')}>
            指數
          </styleds.PageMenuButton>
          <styleds.PageMenuButton active={state.menuSymbolMode === 'stock'} onClick={() => (sungop_store.menuSymbolMode = 'stock')}>
            股票
          </styleds.PageMenuButton>
        </div>
        <div css={css `
            ${scrollbar2Css};
            height: calc(100% - 40px);
          `}>
          {symbolGroup()?.map(symbol => {
            return (<SymbolBidAsk symbol={symbol} key={symbol}></SymbolBidAsk>);
        })}
        </div>
      </styleds.PageMenuSymbolList>
    </styleds.Sidebar>);
});
