import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { reduce } from 'lodash';
import React, { memo, useMemo } from 'react';
import { Bar, ComposedChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis, } from 'recharts-new';
import { fontWeight600 } from '~/css/font';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { getSymbolFromTo } from '~/modules/monitor/getSymbolFromToUtil';
import { getSymbolTicks } from '~/modules/monitor/getSymbolTicksUtil';
import { useSymbolHistoryResource } from '~/modules/screener/containers/useSymbolHistoryResource';
import { useDatePick } from '~/modules/screener/useDatePick';
import dayAPI from '~/utils/dayAPI';
import { priceDiffStore } from './priceDiffStore';
const categoryChartMargin = {
    top: 8,
    right: 8,
    left: 0,
    bottom: 0,
};
/** 目前只支援國內商品 `symbol1：可以放期貨|現貨` `symbol2：只能放現貨商品` */
const PriceDiffChart = memo(function PriceDiffChart(props) {
    /* 最近交易日 */
    const lastTradeString = useDatePick();
    const lastTradeDay = dayAPI(lastTradeString);
    /* 一般日期 */
    const baseDate = new Date().getHours() < 9 || new Date().getDay() === 6 || new Date().getDay() === 0
        ? lastTradeDay
        : dayAPI();
    const resultDate = baseDate;
    const tickRange = getSymbolTicks(props.symbol2, resultDate);
    const indexRange = getSymbolFromTo(props.symbol2, resultDate);
    /* 拿商品1資料 */
    const { data: rawData1 } = useSymbolHistoryResource({
        symbol: props.symbol1,
        fromTo: indexRange,
        resolution: '1',
        intraday: true,
    });
    /* 拿商品2資料 */
    const { data: rawData2 } = useSymbolHistoryResource({
        symbol: props.symbol2,
        fromTo: indexRange,
        resolution: '1',
        intraday: true,
    });
    /** 商品1資料區間 */
    const symbolData1 = useMemo(() => {
        return rawData1?.map(datum => {
            return {
                /** Recharts x 軸時間資料 */
                unixtime: dayAPI(datum.time).unix(),
                /** Recharts 圖表座標 */
                datetime: dayAPI(datum.time).format('HH:mm'),
                /** 收盤價 */
                close: datum.close,
            };
        });
    }, [rawData1]);
    /** 商品2資料區間 */
    const symbolData2 = useMemo(() => {
        return rawData2?.map(datum => {
            return {
                /** Recharts x 軸時間資料 */
                unixtime: dayAPI(datum.time).unix(),
                /** Recharts 圖表座標 */
                datetime: dayAPI(datum.time).format('HH:mm'),
                /** 收盤價 */
                close: datum.close,
            };
        });
    }, [rawData2]);
    //用來判斷是擷取 09:00~13:30 | 08:45~13:44
    const lengthSlice = props.symbol1.includes('-') ? [15, 286] : [0, 300];
    const close1 = symbolData1?.map(a => Math.round(a.close * 1) / 1).slice(lengthSlice[0], lengthSlice[1]) ?? [];
    const close2 = symbolData2?.map(a => Math.round(a.close * 1) / 1) ?? [];
    const dateTime1 = symbolData1?.map(a => a.unixtime).slice(lengthSlice[0], lengthSlice[1]) ?? [];
    const priceDiffValue = close1.map(function (num, idx) {
        return num - close2[idx];
    });
    //純價差 [value: close]
    const diff = priceDiffValue.map(x => ({ value: x }));
    //餵給recharts的data格式
    let resultData = [];
    resultData = reduce(priceDiffValue, (prev, value_, index) => {
        prev[index] = {
            diff: priceDiffValue[index],
            datetime: dateTime1[index],
        };
        return prev;
    }, []);
    //YAxis 設定
    const valueYAxisProps = {
        dataKey: 'value',
        hide: false,
        yAxisId: 1,
        tick: { fontSize: 14, fill: '#cccccc' },
    };
    const maxPriceDiff = Math.max(...diff.map(a => a.value));
    const minPriceDiff = Math.min(...diff.map(a => a.value));
    const aaa = maxPriceDiff < 0 ? 0 : 5;
    const bbb = minPriceDiff > 0 ? 0 : 5;
    const priceDiffRange = [minPriceDiff - bbb, maxPriceDiff + aaa];
    const priceTickRange = [minPriceDiff, maxPriceDiff];
    priceDiffStore.max = maxPriceDiff;
    priceDiffStore.min = minPriceDiff;
    //XAxis 設定
    const xAxisProps = {
        type: 'number',
        dataKey: 'datetime',
        domain: indexRange,
        tickMargin: 0,
        interval: 0,
        ticks: tickRange,
        hide: false,
        height: 20,
        tick: { fontSize: 14, fill: '#cccccc' },
        tickFormatter: timeStr => new Date(timeStr * 1000).getHours().toString(),
    };
    //客製化提示
    const CustomizedTooltip = ({ payload }) => {
        if (!payload || (payload && payload.length < 1))
            return null;
        const datum = payload[0].payload;
        const ItemText = styled.div `
      margin-left: 8px;
      color: ${props_ => props_.color};
      ${fontWeight600}
    `;
        return (<div css={css `
          padding: 0px 5px 0px 5px;
          background-color: ${'#222222'};
          color: ${'#eeeeee'};
          opacity: 0.8;
          border: 1px solid #b4b4b4;
          border-radius: 5px;
          font-size: 14px;
          line-height: 20px;
        `}>
        <div css={flex.h.default}>
          時間
          <ItemText>{dayAPI(datum.datetime * 1000).format('HH:mm')}</ItemText>
        </div>

        <div css={flex.h.default}>
          價差
          <ItemText color={'#2CA8F5'}>{datum.diff?.toFixed(2)}</ItemText>
        </div>
      </div>);
    };
    return (<ResponsiveContainer width='100%' height='100%'>
      <ComposedChart data={resultData} margin={categoryChartMargin}>
        <YAxis {...valueYAxisProps} domain={priceDiffRange} ticks={priceTickRange}/>
        <XAxis {...xAxisProps}/>
        <Bar yAxisId={1} dataKey='diff' barSize={1} isAnimationActive={false} fill={'#2763D6'}/>
        <ReferenceLine yAxisId={1} isFront={true} y={maxPriceDiff} stroke={'#ff2222'} strokeWidth={0.4} label={{ value: maxPriceDiff, fill: '#ff2222', fontSize: 14 }} strokeDasharray={'3 3'}/>
        <ReferenceLine yAxisId={1} isFront={true} y={minPriceDiff} stroke={'#11ff11'} strokeWidth={0.4} label={{ value: minPriceDiff, fill: '#11ff11', fontSize: 14 }} strokeDasharray={'3 3'}/>
        <Tooltip content={<CustomizedTooltip />}/>
      </ComposedChart>
    </ResponsiveContainer>);
});
export default PriceDiffChart;
