import { SessionType } from '~/modules/SDK/Chart2/SessionType';
import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const op_daytrade_signal = createIndicator({
    displayName: '當沖提訊點',
    id: 'op-daytrade-signal',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const sessionType = SessionType.TAIFEX_AM;
            const dateTime = new Date(this.PineJS.Std.time(this._context));
            const high = this.ohlc.highArray;
            const low = this.ohlc.lowArray;
            const close = this.ohlc.closeArray;
            const open = this.ohlc.openArray;
            const flag = this._context.new_var();
            high.get(10);
            low.get(10);
            close.get(10);
            // -------------------------------------- Indicators --------------------------------------
            const hansHigh = this._context.new_var();
            const hansLow = this._context.new_var();
            const dayHigh = this._context.new_var();
            const dayLow = this._context.new_var();
            // Reset high low at first bar
            if (this.isSessionFirstBar(sessionType)) {
                hansHigh.set(0);
                hansLow.set(0);
                dayHigh.set(high.get(0));
                dayLow.set(low.get(0));
            }
            else if (dateTime.getHours() === 9 && dateTime.getMinutes() === 0) {
                hansHigh.set(dayHigh.get(0));
                hansLow.set(dayLow.get(0));
            }
            else if (dateTime.getHours() < 14) {
                dayHigh.set(this.maxList([high.get(0), dayHigh.get(0)]));
                dayLow.set(this.minList([low.get(0), dayLow.get(0)]));
            }
            // -------------------------------------- Entries --------------------------------------
            let longFlag = 0;
            let shortFlag = 0;
            const breakoutTimeFilter = dateTime.getHours() >= 9 && dateTime.getHours() <= 14;
            const symbol = this._context.symbol.info?.name;
            /** 強多 */
            const strongLong = symbol?.includes('TX') &&
                close.get(0) > hansHigh.get(0) &&
                hansHigh.get(0) !== 0 &&
                breakoutTimeFilter &&
                close.get(0) > close.get(1);
            /** 強空 */
            const strongShort = symbol?.includes('TX') &&
                close.get(0) < hansLow.get(0) &&
                hansLow.get(0) !== 0 &&
                breakoutTimeFilter &&
                close.get(0) < close.get(1);
            //進場--------------------------------------------------
            let buy_icon = NaN;
            let short_icon = NaN;
            let buy_target_icon = NaN;
            let short_target_icon = NaN;
            const entryPrice = this._context.new_var();
            const targetPrice = this._context.new_var();
            const position = this._context.new_var();
            const targetPoint = this._context.new_var();
            position.get(2);
            if (strongLong) {
                longFlag = 1;
                position.set(1);
            }
            if (strongShort) {
                shortFlag = -1;
                position.set(-1);
            }
            //顯示訊號--------------------------------------------------
            if (position.get(1) !== 1 && position.get(0) === 1) {
                buy_icon = 1;
                targetPrice.set(close);
                targetPoint.set(1.001);
            }
            if (position.get(1) !== -1 && position.get(0) === -1) {
                short_icon = 1;
                targetPrice.set(close);
                targetPoint.set(0.999);
            }
            //停利訊號--------------------------------------------------
            //每完成一次停利訊號 停利點數就會再放大
            if (position.get(1) === 1 && position.get(0) === 1) {
                if (high > targetPrice.get(0) * targetPoint.get(0)) {
                    targetPoint.set(targetPoint.get(0) + 0.0005);
                    targetPrice.set(targetPrice.get(0) * targetPoint.get(0));
                    buy_target_icon = 1;
                }
            }
            if (position.get(1) === -1 && position.get(0) === -1) {
                if (low < targetPrice.get(0) * targetPoint.get(0)) {
                    targetPoint.set(targetPoint.get(0) - 0.0005);
                    targetPrice.set(targetPrice.get(0) * targetPoint.get(0));
                    short_target_icon = 1;
                }
            }
            //sar出場
            const strength = this._input(0);
            const sar_stdv = this._input(1);
            const sar_af = this._input(2);
            const sar_long = this.sarLongStopLoss('nonLinear', 4, position, 10, 2, 0.06, 1);
            const sar_short = this.sarShortStopLoss('nonLinear', 5, position, 10, 2, 0.08, 1);
            const longEnd = position.get(1) === 1 && position.get(0) === 1 && close < sar_long.get(0);
            const shortEnd = position.get(1) === -1 && position.get(0) === -1 && close > sar_short.get(0);
            if (longEnd) {
                position.set(0);
                targetPrice.set(NaN);
            }
            if (shortEnd) {
                position.set(0);
                targetPrice.set(NaN);
            }
            //sar線--------------------------------------------------
            const sarLongLine = this._context.new_var();
            const sarShortLine = this._context.new_var();
            if (position.get(0) === 1) {
                sarLongLine.set(sar_long.get(0));
                sarShortLine.set(NaN);
            }
            if (position.get(0) === -1) {
                sarLongLine.set(NaN);
                sarShortLine.set(sar_short.get(0));
            }
            if (position.get(0) === 0) {
                sarLongLine.set(NaN);
                sarShortLine.set(NaN);
            }
            return [
                buy_icon,
                short_icon,
                buy_target_icon,
                short_target_icon,
                sarLongLine.get(0),
                sarShortLine.get(0),
            ];
        },
    },
    metainfo: {
        defaults: {
            styles: {
                plot_0: {
                    color: '#dd5e56',
                    textColor: '#dd5e56',
                    transparency: 0,
                    visible: true,
                },
                plot_1: {
                    color: '#52a49a',
                    textColor: '#52a49a',
                    transparency: 0,
                    visible: true,
                },
                plot_2: {
                    color: '#dd5e56',
                    textColor: '#dd5e56',
                    transparency: 30,
                    visible: true,
                },
                plot_3: {
                    color: '#52a49a',
                    textColor: '#52a49a',
                    transparency: 30,
                    visible: true,
                },
                plot_4: {
                    color: '#dd5e56',
                    textColor: '#dd5e56',
                    transparency: 30,
                    visible: true,
                    linestyle: 2,
                    linewidth: 1,
                },
                plot_5: {
                    color: '#52a49a',
                    textColor: '#52a49a',
                    transparency: 30,
                    visible: true,
                    linestyle: 2,
                    linewidth: 1,
                },
            },
            inputs: {
            //in_0: 0, in_1: 4, in_2: 0.001
            },
        },
        plots: [
            {
                id: 'plot_0',
                type: 'chars',
            },
            {
                id: 'plot_1',
                type: 'chars',
            },
            {
                id: 'plot_2',
                type: 'chars',
            },
            {
                id: 'plot_3',
                type: 'chars',
            },
            {
                id: 'plot_4',
                type: 'line',
            },
            {
                id: 'plot_5',
                type: 'line',
            },
        ],
        styles: {
            plot_0: {
                isHidden: false,
                location: 'BelowBar',
                char: '↑',
                size: 'small',
                text: 'B',
                title: '多單參考訊號',
            },
            plot_1: {
                isHidden: false,
                location: 'AboveBar',
                char: '↓',
                size: 'small',
                text: 'S',
                title: '空單參考訊號',
            },
            plot_2: {
                isHidden: false,
                location: 'AboveBar',
                char: '★',
                size: 'tiny',
                text: '',
                title: '多單出場訊號',
            },
            plot_3: {
                isHidden: false,
                location: 'BelowBar',
                char: '★',
                size: 'tiny',
                text: '',
                title: '空單出場訊號',
            },
            plot_4: {
                isHidden: false,
                title: '多單出場參考線',
            },
            plot_5: {
                isHidden: false,
                title: '空單出場參考線',
            },
        },
        is_price_study: !0,
        inputs: [
        // {
        //   id: 'in_0',
        //   name: 'strength',
        //   defval: 0,
        //   type: 'integer',
        //   min: 0,
        //   max: 10,
        // },
        // {
        //   id: 'in_1',
        //   name: 'stdv',
        //   defval: 4,
        //   type: 'float',
        //   min: 1,
        //   max: 20,
        // },
        // {
        //   id: 'in_2',
        //   name: 'af',
        //   defval: 0.001,
        //   type: 'float',
        //   min: 0.0001,
        //   max: 0.1,
        // },
        ],
        scriptIdPart: '',
    },
});
