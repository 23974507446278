import styled from '@emotion/styled';
import { memo } from 'react';
import { fontWeight400 } from '~/css/font';
import { fill_horizontal_all_center, jc, pureGrid } from '~/modules/AppLayout/FlexGridCss';
import QuoteValue from '~/modules/page_modules/cons_modules/SymbolQuote/QuoteValue';
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName';
//方塊報價的元件
export const BodyItem = memo(function BodyItem(props) {
    return (<styleds.Container className={SquareBodyItemClasses.Container} onClick={props.handleClick}>
      <styleds.ItemName className={SquareBodyItemClasses.ItemName}>
        {props.symbol}
        <SymbolName symbol={props.symbol}/>
      </styleds.ItemName>
      <styleds.ItemValueClose>
        <QuoteValue.Close symbolNumber={props.symbol}/>
      </styleds.ItemValueClose>
      <styleds.ItemValueChange>
        <QuoteValue.Change symbolNumber={props.symbol}/>
        <QuoteValue.ChangePrecentage symbolNumber={props.symbol}/>
      </styleds.ItemValueChange>
    </styleds.Container>);
});
const styleds = {
    Container: styled.div `
    ${pureGrid};
    width: 100%;
    height: 80px;
    justify-items: center;
    grid-template-rows: 28% 44% 28%;
    font-size: 14px;
    background-color: #111111;
    border: 1px solid #444444;
    border-radius: 8px;
    padding: 4px;
    cursor: pointer;
    &:hover {
      background-color: #1a1a1a;
      transition: 0.3s;
    }
  `,
    ItemName: styled.div `
    ${fill_horizontal_all_center}
    background-color: #222222;
    border-radius: 4px;
    width: 66%;
    ${fontWeight400};
  `,
    ItemValueClose: styled.div `
    ${fill_horizontal_all_center}
    font-size: 16px;
  `,
    ItemValueChange: styled.div `
    ${fill_horizontal_all_center}
    ${jc.spaceAround}
  `,
};
export const SquareBodyItemClasses = {
    Container: `${BodyItem.name}-Container`,
    ItemName: `${BodyItem.name}-ItemName`,
};
