import { css } from '@emotion/react';
import { memo } from 'react';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
export const Sungop_OptionState = memo(function Sungop_OptionState() {
    return (<div css={css `
        ${fill_vertical_cross_center};
        padding: 4px;
        background-color: #181a1f;
        ${scrollbar2Css};
      `}>
      <iframe width='100%' height='98%' frameBorder='0' src='https://options-oi.futures-op.com/options-oi-bar'/>
    </div>);
});
