import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const op_signal = createIndicator({
    displayName: '波段提訊點',
    id: 'op-signal',
    constructorScope: {
        init(context, inputCallback) {
            const ticker = this.PineJS.Std.ticker(this._context)
                .toString()
                .replace(/[^\w-]/g, '');
            const symbol = ticker.includes('AM') ? 'TXAM-1#opmf_acc' : 'TX-1#opmf_acc';
            this._context.new_sym(symbol, this.PineJS.Std.period(this._context));
            const ticker2 = this.PineJS.Std.ticker(this._context)
                .toString()
                .replace(/[^\w-]/g, '');
            const symbol2 = ticker2 + '#bs_indicator_acc';
            this._context.new_sym(symbol2, this.PineJS.Std.period(this._context));
        },
        main(context, inputCallback) {
            const i = context.new_unlimited_var(this._context.symbol.time);
            this._context.select_sym(1);
            const time1 = this._context.new_unlimited_var(this._context.symbol.time);
            const callData = this.PineJS.Std.low(this._context);
            const putData = this.PineJS.Std.close(this._context);
            const unlimited1 = this._context.new_unlimited_var(callData);
            const unlimited2 = this._context.new_unlimited_var(putData);
            const call = unlimited1.adopt(time1, i, 0); // <=這是籌碼
            const put = unlimited2.adopt(time1, i, 0); // <=這是籌碼
            const value1_array = this._context.new_var(call);
            const value2_array = this._context.new_var(put);
            value1_array.get(1000);
            value2_array.get(1000);
            //#bs_indicator_acc------------------------------------------------
            this._context.select_sym(2);
            const time3 = this._context.new_unlimited_var(this._context.symbol.time);
            const quote3 = this.PineJS.Std.high(this._context);
            const unlimited3 = this._context.new_unlimited_var(quote3);
            const power = unlimited3.adopt(time3, i, 0); // <=這是籌碼
            const value3_array = this._context.new_var(power);
            value3_array.get(1000);
            this._context.select_sym(0);
            this.PineJS.Std.period(this._context);
            const close = this.PineJS.Std.close(this._context);
            const high = this.PineJS.Std.high(this._context);
            const low = this.PineJS.Std.low(this._context);
            /** 強多 */
            const strongLong = power > 10 && call > 0 && put < 0;
            /** 強空 */
            const strongShort = power < 0 && call < 0 && put > 0;
            //進場--------------------------------------------------
            let buy_icon = NaN;
            let short_icon = NaN;
            let buy_target_icon = NaN;
            let short_target_icon = NaN;
            const entryPrice = this._context.new_var();
            const targetPrice = this._context.new_var();
            const position = this._context.new_var();
            const targetPoint = this._context.new_var();
            position.get(2);
            if (strongLong) {
                position.set(1);
            }
            if (strongShort) {
                position.set(-1);
            }
            //顯示訊號--------------------------------------------------
            if (position.get(1) !== 1 && position.get(0) === 1) {
                buy_icon = 1;
                targetPrice.set(close);
                targetPoint.set(1.001);
            }
            if (position.get(1) !== -1 && position.get(0) === -1) {
                short_icon = 1;
                targetPrice.set(close);
                targetPoint.set(0.999);
            }
            //停利訊號--------------------------------------------------
            //每完成一次停利訊號 停利點數就會再放大
            if (position.get(1) === 1 && position.get(0) === 1) {
                if (high > targetPrice.get(0) * targetPoint.get(0)) {
                    targetPoint.set(targetPoint.get(0) + 0.001);
                    targetPrice.set(targetPrice.get(0) * targetPoint.get(0));
                    buy_target_icon = 1;
                }
            }
            if (position.get(1) === -1 && position.get(0) === -1) {
                if (low < targetPrice.get(0) * targetPoint.get(0)) {
                    targetPoint.set(targetPoint.get(0) - 0.001);
                    targetPrice.set(targetPrice.get(0) * targetPoint.get(0));
                    short_target_icon = 1;
                }
            }
            //sar出場
            const strength = this._input(0);
            const sar_stdv = this._input(1);
            const sar_af = this._input(2);
            const sar_long = this.sarLongStopLoss('linear', 7, position, 10, 4, 0.06, 1);
            const sar_short = this.sarShortStopLoss('linear', 9, position, 10, 4, 0.08, 1);
            const longEnd = position.get(1) === 1 && position.get(0) === 1 && close < sar_long.get(0);
            const shortEnd = position.get(1) === -1 && position.get(0) === -1 && close > sar_short.get(0);
            if (longEnd) {
                position.set(0);
                targetPrice.set(NaN);
            }
            if (shortEnd) {
                position.set(0);
                targetPrice.set(NaN);
            }
            //sar線--------------------------------------------------
            const sarLongLine = this._context.new_var();
            const sarShortLine = this._context.new_var();
            if (position.get(0) === 1) {
                sarLongLine.set(sar_long.get(0));
                sarShortLine.set(NaN);
            }
            if (position.get(0) === -1) {
                sarLongLine.set(NaN);
                sarShortLine.set(sar_short.get(0));
            }
            if (position.get(0) === 0) {
                sarLongLine.set(NaN);
                sarShortLine.set(NaN);
            }
            return [
                buy_icon,
                short_icon,
                buy_target_icon,
                short_target_icon,
                sarLongLine.get(0),
                sarShortLine.get(0),
            ];
        },
    },
    metainfo: {
        defaults: {
            styles: {
                plot_0: {
                    color: '#dd5e56',
                    textColor: '#dd5e56',
                    transparency: 0,
                    visible: true,
                },
                plot_1: {
                    color: '#52a49a',
                    textColor: '#52a49a',
                    transparency: 0,
                    visible: true,
                },
                plot_2: {
                    color: '#dd5e56',
                    textColor: '#dd5e56',
                    transparency: 30,
                    visible: true,
                },
                plot_3: {
                    color: '#52a49a',
                    textColor: '#52a49a',
                    transparency: 30,
                    visible: true,
                },
                plot_4: {
                    color: '#dd5e56',
                    textColor: '#dd5e56',
                    transparency: 30,
                    visible: true,
                    linestyle: 2,
                    linewidth: 1,
                },
                plot_5: {
                    color: '#52a49a',
                    textColor: '#52a49a',
                    transparency: 30,
                    visible: true,
                    linestyle: 2,
                    linewidth: 1,
                },
            },
            inputs: {
            //in_0: 0, in_1: 4, in_2: 0.001
            },
        },
        plots: [
            {
                id: 'plot_0',
                type: 'chars',
            },
            {
                id: 'plot_1',
                type: 'chars',
            },
            {
                id: 'plot_2',
                type: 'chars',
            },
            {
                id: 'plot_3',
                type: 'chars',
            },
            {
                id: 'plot_4',
                type: 'line',
            },
            {
                id: 'plot_5',
                type: 'line',
            },
        ],
        styles: {
            plot_0: {
                isHidden: false,
                location: 'BelowBar',
                char: '▲',
                size: 'small',
                text: 'B',
                title: '多單參考訊號',
            },
            plot_1: {
                isHidden: false,
                location: 'AboveBar',
                char: '▼',
                size: 'small',
                text: 'S',
                title: '空單參考訊號',
            },
            plot_2: {
                isHidden: false,
                location: 'AboveBar',
                char: '★',
                size: 'tiny',
                text: '',
                title: '多單出場訊號',
            },
            plot_3: {
                isHidden: false,
                location: 'BelowBar',
                char: '★',
                size: 'tiny',
                text: '',
                title: '空單出場訊號',
            },
            plot_4: {
                isHidden: false,
                title: '多單出場參考線',
            },
            plot_5: {
                isHidden: false,
                title: '空單出場參考線',
            },
        },
        is_price_study: !0,
        inputs: [
        // {
        //   id: 'in_0',
        //   name: 'strength',
        //   defval: 0,
        //   type: 'integer',
        //   min: 0,
        //   max: 10,
        // },
        // {
        //   id: 'in_1',
        //   name: 'stdv',
        //   defval: 4,
        //   type: 'float',
        //   min: 1,
        //   max: 20,
        // },
        // {
        //   id: 'in_2',
        //   name: 'af',
        //   defval: 0.001,
        //   type: 'float',
        //   min: 0.0001,
        //   max: 0.1,
        // },
        ],
        scriptIdPart: '',
    },
});
