import { css } from '@emotion/react';
import { zipWith } from 'lodash';
import { memo } from 'react';
import { fill_horizontal_all_center, fill_vertical_all_center, } from '~/modules/AppLayout/FlexGridCss';
import dayAPI from '~/utils/dayAPI';
import { ChipsChart } from './ChipsChart';
//近N日籌碼每日增減圖表 (rechart圖表)
export const ChipValueDataChart = memo(function ChipValueDataChart(props) {
    let data = [];
    data = (!props.data || !props.date
        ? []
        : zipWith(props.data, props.date, (value, date) => ({
            value,
            date: dayAPI(date * 1000).format('MM/DD'),
        }))).splice(-(props.dataLenght ?? 7));
    const currentDiff = props.data?.[props.data.length - 1] ?? 0;
    return (<div css={css `
        ${fill_horizontal_all_center};
        height: 84px;
        padding: 4px;
        //background-color: #2d303e;
        //border-radius: 5px;
        border-bottom: 1px solid #555555;
      `}>
      <div css={css `
          ${fill_vertical_all_center}
          width: 30%;
          height: 100%;
          font-size: 10px;
        `}>
        <ChipTitle title={props.title} unit={props.unit} valueDiff={currentDiff ?? 0}/>
      </div>
      <div css={css `
          width: 70%;
          height: 100%;
          font-size: 10px;
        `}>
        <ChipsChart data={data}/>
      </div>
    </div>);
});
export const ChipTitle = memo(function ChipTitle(props) {
    const value = props.valueDiff;
    const diffValueColor = () => value ? (value > 0 ? '#f11f1f' : value < 0 ? '#009900' : '#555555') : '#555555';
    const chipValue = () => value > 100000 || value < -100000 ? ((value ?? 0) / 100000000).toFixed(2) : value ?? 0;
    const symbol = value > 0 ? '+' : '';
    const displayValue = value === 0 ? '資料更新中' : chipValue() + props.unit;
    return (<div css={css `
          ${fill_vertical_all_center};
          align-items: start;
          justify-content: center;
          font-size: 14px;
          padding-left: 8px;
        `}>
        <div>{props.title}</div>
        <div css={css `
            color: ${diffValueColor()};
          `}>
          {symbol} {displayValue}
        </div>
      </div>);
});
