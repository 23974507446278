import { useEffect } from 'react';
import { useSignalrStore } from '../Signalr/useSignalrStore';
import { useSignalrStoreValueOHLC } from '../Signalr/useSignalrStoreValueOHLC';
/** 返回`價差:number`&`時間:string` 未來請yu chen 做後端的期現價差api */
const usePriceDiffValue = () => {
    const symbolInfo1 = useSignalrStoreValueOHLC(state => state.value['TXAM-1']);
    const symbolInfo2 = useSignalrStoreValueOHLC(state => state.value['TSEA']);
    useEffect(() => {
        useSignalrStore.getState().subscribeAdd(['TXAM-1', 'TSEA'], 'ohlc');
        return () => {
            useSignalrStore.getState().subscribeRemove(['TXAM-1', 'TSEA'], 'ohlc');
        };
    }, []);
    const close1 = symbolInfo1?.close ?? 0;
    const close2 = symbolInfo2?.close ?? 0;
    const value = Math.round((close1 - close2) * 1) / 1;
    const time = symbolInfo1?.datetime ?? '';
    const timeValue = new Date(time).toLocaleTimeString([], {
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
    });
    return {
        diffValue: value,
        diffUpdateTime: timeValue,
    };
};
export default usePriceDiffValue;
