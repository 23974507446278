import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import dayAPI from '~/utils/dayAPI';
export const op_daytrade_signal_pm = createIndicator({
    displayName: '夜盤當沖提訊點',
    id: 'op-daytrade-signal-pm',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const itime = this.PineJS.Std.time(this._context);
            const itime_array = this._context.new_var(itime);
            const high = this.ohlc.highArray;
            const low = this.ohlc.lowArray;
            const close = this.ohlc.closeArray;
            const highest = this.PineJS.Std.highest(high, 5, this._context);
            const lowest = this.PineJS.Std.highest(low, 5, this._context);
            const timeString = dayAPI(itime).format('HH:mm');
            const dayHigh = this._context.new_var();
            const dayLow = this._context.new_var();
            const exitTime = this._context.new_var();
            high.get(10);
            low.get(10);
            close.get(10);
            itime_array.get(5);
            /** 20:30歸零 20:30 ~ 21:30統計 02:35歸零 沒數據就無法交易 */
            if (timeString === '20:30') {
                dayHigh.set(high.get(0));
                dayLow.set(low.get(0));
                exitTime.set(itime);
            }
            else if (timeString > '20:30' && timeString <= '21:30') {
                dayHigh.set(this.maxList([high.get(0), dayHigh.get(0)]));
                dayLow.set(this.minList([low.get(0), dayLow.get(0)]));
            }
            else if (timeString === '02:35') {
                dayHigh.set(NaN);
                dayLow.set(NaN);
            }
            const symbol = this._context.symbol.info?.name;
            /** 強多 */
            const strongLong = symbol?.includes('TX') &&
                itime_array.get(5) > exitTime.get(0) &&
                lowest < dayHigh.get(0) &&
                close.get(0) > dayHigh.get(0);
            /** 強空 */
            const strongShort = symbol?.includes('TX') &&
                itime_array.get(5) > exitTime.get(0) &&
                highest > dayLow.get(0) &&
                close.get(0) < dayLow.get(0);
            //進場--------------------------------------------------
            let buy_icon = NaN;
            let short_icon = NaN;
            let buy_target_icon = NaN;
            let short_target_icon = NaN;
            const targetPrice = this._context.new_var();
            const position = this._context.new_var();
            const targetPoint = this._context.new_var();
            position.get(2);
            if (strongLong) {
                position.set(1);
            }
            if (strongShort) {
                position.set(-1);
            }
            //顯示訊號--------------------------------------------------
            if (position.get(1) !== 1 && position.get(0) === 1) {
                buy_icon = 1;
                targetPrice.set(close);
                targetPoint.set(1.001);
            }
            if (position.get(1) !== -1 && position.get(0) === -1) {
                short_icon = 1;
                targetPrice.set(close);
                targetPoint.set(0.999);
            }
            //停利訊號--------------------------------------------------
            //每完成一次停利訊號 停利點數就會再放大
            if (position.get(1) === 1 && position.get(0) === 1) {
                if (high > targetPrice.get(0) * targetPoint.get(0)) {
                    targetPoint.set(targetPoint.get(0) + 0.001);
                    targetPrice.set(targetPrice.get(0) * targetPoint.get(0));
                    buy_target_icon = 1;
                }
            }
            if (position.get(1) === -1 && position.get(0) === -1) {
                if (low < targetPrice.get(0) * targetPoint.get(0)) {
                    targetPoint.set(targetPoint.get(0) - 0.001);
                    targetPrice.set(targetPrice.get(0) * targetPoint.get(0));
                    short_target_icon = 1;
                }
            }
            //sar出場
            const sar_long = this.sarLongStopLoss('linear', 9, position, 10, 1.2, 0.12, 2);
            const sar_short = this.sarShortStopLoss('linear', 9, position, 10, 1.2, 0.22, 2);
            const longEnd = position.get(1) === 1 && position.get(0) === 1 && close < sar_long.get(0);
            const shortEnd = position.get(1) === -1 && position.get(0) === -1 && close > sar_short.get(0);
            if (longEnd) {
                position.set(0);
                targetPrice.set(NaN);
                exitTime.set(itime);
            }
            if (shortEnd) {
                position.set(0);
                targetPrice.set(NaN);
                exitTime.set(itime);
            }
            //sar線--------------------------------------------------
            const sarLongLine = this._context.new_var();
            const sarShortLine = this._context.new_var();
            if (position.get(0) === 1) {
                sarLongLine.set(sar_long.get(0));
                sarShortLine.set(NaN);
            }
            if (position.get(0) === -1) {
                sarLongLine.set(NaN);
                sarShortLine.set(sar_short.get(0));
            }
            if (position.get(0) === 0) {
                sarLongLine.set(NaN);
                sarShortLine.set(NaN);
            }
            return [
                buy_icon,
                short_icon,
                buy_target_icon,
                short_target_icon,
                sarLongLine.get(0),
                sarShortLine.get(0),
                // dayHigh.get(0),
                // dayLow.get(0),
            ];
        },
    },
    metainfo: {
        defaults: {
            styles: {
                plot_0: {
                    color: '#dd5e56',
                    textColor: '#dd5e56',
                    transparency: 0,
                    visible: true,
                },
                plot_1: {
                    color: '#52a49a',
                    textColor: '#52a49a',
                    transparency: 0,
                    visible: true,
                },
                plot_2: {
                    color: '#dd5e56',
                    textColor: '#dd5e56',
                    transparency: 30,
                    visible: true,
                },
                plot_3: {
                    color: '#52a49a',
                    textColor: '#52a49a',
                    transparency: 30,
                    visible: true,
                },
                plot_4: {
                    color: '#dd5e56',
                    textColor: '#dd5e56',
                    transparency: 30,
                    visible: true,
                    linestyle: 2,
                    linewidth: 1,
                },
                plot_5: {
                    color: '#52a49a',
                    textColor: '#52a49a',
                    transparency: 30,
                    visible: true,
                    linestyle: 2,
                    linewidth: 1,
                },
            },
            inputs: {
            //in_0: 0, in_1: 4, in_2: 0.001
            },
        },
        plots: [
            {
                id: 'plot_0',
                type: 'chars',
            },
            {
                id: 'plot_1',
                type: 'chars',
            },
            {
                id: 'plot_2',
                type: 'chars',
            },
            {
                id: 'plot_3',
                type: 'chars',
            },
            {
                id: 'plot_4',
                type: 'line',
            },
            {
                id: 'plot_5',
                type: 'line',
            },
            {
                id: 'plot_6',
                type: 'line',
            },
            {
                id: 'plot_7',
                type: 'line',
            },
        ],
        styles: {
            plot_0: {
                isHidden: false,
                location: 'BelowBar',
                char: '▲',
                size: 'small',
                text: 'B',
                title: '多單參考訊號',
            },
            plot_1: {
                isHidden: false,
                location: 'AboveBar',
                char: '▼',
                size: 'small',
                text: 'S',
                title: '空單參考訊號',
            },
            plot_2: {
                isHidden: false,
                location: 'AboveBar',
                char: '★',
                size: 'tiny',
                text: '',
                title: '多單出場訊號',
            },
            plot_3: {
                isHidden: false,
                location: 'BelowBar',
                char: '★',
                size: 'tiny',
                text: '',
                title: '空單出場訊號',
            },
            plot_4: {
                isHidden: false,
                title: '多單出場參考線',
            },
            plot_5: {
                isHidden: false,
                title: '空單出場參考線',
            },
        },
        is_price_study: !0,
        inputs: [
        // {
        //   id: 'in_0',
        //   name: 'strength',
        //   defval: 0,
        //   type: 'integer',
        //   min: 0,
        //   max: 10,
        // },
        // {
        //   id: 'in_1',
        //   name: 'stdv',
        //   defval: 4,
        //   type: 'float',
        //   min: 1,
        //   max: 20,
        // },
        // {
        //   id: 'in_2',
        //   name: 'af',
        //   defval: 0.001,
        //   type: 'float',
        //   min: 0.0001,
        //   max: 0.1,
        // },
        ],
        scriptIdPart: '',
    },
});
